import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {VitaCareplanMetric} from "../prohealth/vita-services/vita-careplan.service";
import {MetricsType} from "../prohealth/vita-services/vita-metrics.service";
import {CallLogMetric} from "../prohealth/vita-readings/vita-readings-service/vita-readings.model";



@Pipe({
  name: 'nameCase',
  pure: true
})
export class NameCasePipe implements PipeTransform {

  transform(string) {
    if (string) return string.replace(/\w\S*/g, (txt) => {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    else return string
  }
}

@Pipe({
  name: 'numberOrdinal',
  pure: true
})
export class NumberOrdinalPipe implements PipeTransform {

  transform(num) {
    if (num > 3 && num < 21) return 'th';
    switch (num % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }
}

@Pipe({
  name: 'stringToComparator',
  pure: true
})
export class StringToComparatorPipe implements PipeTransform {

  transform(string) {
    if (string === "lessThan") return "<";
    if (string === "greaterThan") return ">";
    if (string === "equalTo") return "=";
  }
}


@Pipe({
  name: 'stringArrayToPipedList',
  pure: true
})
export class StringToArrayToPipedList implements PipeTransform {

  transform(stringArray: string[] | null) {
    let stringToDisplay = "";
    if (stringArray && stringArray.length){
      stringArray.forEach((str) => {
        stringToDisplay += " " + str + " | "
      });
      if (stringToDisplay.length > 2) stringToDisplay = stringToDisplay.substring(0, stringToDisplay.length - 2);
    }
    return stringToDisplay
  }
}



@Pipe({
  name: 'dayOfMonth'
})
export class DayOfMonth implements PipeTransform{

  transform(day: number): string {
    if (day < 1 || day > 31) return  day + "";
    if (day >= 11 && day <= 13) {
      return day + "th";
    }
    switch (day % 10) {
      case 1:  return day + "st";
      case 2:  return day + "nd";
      case 3:  return day + "rd";
      default: return day + "th";
    }
  }

}


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}


@Pipe({ name: 'metricIdToName' })
export class MetricIdToNamePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(metric: VitaCareplanMetric) {
    switch (metric) {
      case MetricsType.METRIC_BG:
        return "Blood Glucose";
      case MetricsType.METRIC_BP:
        return "Blood Pressure";
      case MetricsType.METRIC_BODY:
        return "Weight";
      case MetricsType.METRIC_HBA1C:
        return "HbA1c";
      case MetricsType.METRIC_LIPID:
        return "Lipid";
      case MetricsType.METRIC_THYROID:
        return "Thyroid";
      case MetricsType.METRIC_TEMPERATURE:
        return "Temperature";
      case MetricsType.METRIC_SPO2:
        return "SpO2";

      case MetricsType.METRIC_REPORT:
        return "Report";

      case VitaCareplanMetric.METRIC_STRESS:
        return "Stress";
      case VitaCareplanMetric.METRIC_DIET:
        return "Diet";
      case VitaCareplanMetric.Activity:
        return "Physical Activity";

      case VitaCareplanMetric.Medication:
        return "Medication";
      case VitaCareplanMetric.Insulin:
        return "Insulin";
      default:
        return "";
    }
  }
}
@Pipe({
  name: 'userRedableString',
  pure: true
})
export class UserReadableString implements PipeTransform {
  transform(str: string | null) {
    let readableString:string="";
    console.log("str",str);
    if (str){
      readableString = str.replace(/_/g, " ");
    }
   
    return readableString
  }
}

/*
  BLOOD_PRESSURE : "Blood pressure",
  BODY: "body",
  HEMOGLOBIN: "Hemoglobin",
  CBP: "CBP",
  CUE: "CUE",
  HIV: "HIV",
  VDRL: "VDRL",
  HBASG: "HBsAg",
  PRE_PREGNANCY_WEIGHT: "Pre Pregnancy Weight",
  COMPLAINTS: "Complaints",
  MEDICAL_CONDITION: "Medical Condition",
  LAST_ANTENTAL_CHECK: "Last Antental Check",
  SLEEP_PATTERN: "Sleep Pattern",
  STATUS_OF_PHYSICAL_ACTIVITY: "Status of Physical Activity",
  ULTRASOUND_SCAN:"Ultrasound Scan",
  TT_IMMUNIZATION1: "TT Immunization - 1",
  TT_IMMUNIZATION2: "TT Immunization - 2",
  MEDICATION: "Medications",
  DIET: "Diet",
 */

@Pipe({ name: 'metricIdToReadableName' })
export class MetricIdToReadableNamePipe implements PipeTransform{
  transform(metricId: string): string {
      switch (metricId) {
        case CallLogMetric.BLOOD_PRESSURE:
          return "Blood pressure";
        case CallLogMetric.BODY:
          return "Body";
        case CallLogMetric.HEMOGLOBIN:
          return "Hemoglobin";
        case CallLogMetric.CBP:
          return "CBP";
        case CallLogMetric.CUE:
          return "CUE";
        case CallLogMetric.HIV:
          return "HIV";
        case CallLogMetric.VDRL:
          return "VDRL";
        case CallLogMetric.HBSAG:
          return "HBsAg";
        case CallLogMetric.PRE_PREGNANCY_WEIGHT:
          return "Pre Pregnancy Weight";
        case CallLogMetric.COMPLAINTS:
          return "Complaints";
        case CallLogMetric.MEDICAL_CONDITION:
          return "Medical Condition";
        case CallLogMetric.LAST_ANTENTAL_CHECK:
          return "Last Antental Check";
        case CallLogMetric.SLEEP_PATTERN:
          return "Sleep Pattern";
        case CallLogMetric.STATUS_OF_PHYSICAL_ACTIVITY:
          return "Status of Physical Activity";
        case CallLogMetric.ULTRASOUND_SCAN:
          return "Ultrasound Scan";
        // case CallLogMetric.TT_IMMUNIZATION:
        //   return "TT Immunization";
        case CallLogMetric.MEDICATION:
          return "Medications";
        case CallLogMetric.DIET:
          return "Diet";
        case CallLogMetric.DELIVERY_DETAILS:
          return "Delivery Details";
        case CallLogMetric.BABY:
          return "Baby";
        // case CallLogMetric.WOUND_CONDITION:
        //   return "Wound Condition & Hygiene";
        case CallLogMetric.POST_NATAL:
          return "Post Natal";
        // case CallLogMetric.DIETARY_ADVICE:
        //   return "Dietary Advice";
        // case CallLogMetric.GENERAL_ADVICE:
        //   return "General Advice";
        // case CallLogMetric.GESTATIONAL_WEEK:
        //   return "Gestational Week";
        case CallLogMetric.MISCARRIAGE:
          return "Miscarriage";

        case CallLogMetric.EARLY_ULTRASOUND_SCAN:
          return "Early Ultrasound scan";

        case CallLogMetric.SCREENING_FOR_GESTATIONAL_DIABETES:
          return "Screening for Gestational Diabetes";

        case CallLogMetric.SIGNS_OF_PIH:
          return "Signs of PIH";

        case CallLogMetric.SIGNS_OF_GDM:
          return "Signs of GDM";

        case CallLogMetric.ANTENTAL_CHECK_DONE_TILL_DATE:
          return "Antenatal Checkups done till date";

        case CallLogMetric.IMMUNIZATION_COUNSELLING_DONE:
          return "Immunization Counselling done";

        case CallLogMetric.BREASTFEEDING_COUNSELLING_DONE:
          return "Breast feeding Counselling done";

        // POST NATAL CALL
        case CallLogMetric.VACCINATION_CHART: 
          return "Vaccination / Chart";

        case CallLogMetric.VACCINATION_GIVEN:
          return "Vaccination Given";

        case CallLogMetric.BABY_AGE:
          return "Baby Age";

        case CallLogMetric.BABY_WEIGHT:
          return "Baby Weight";

        case CallLogMetric.EXCEPCTED_BABY_WEIGHT:
          return "Expected Baby Weight";

        case CallLogMetric.COMPLICATIONS_INFANT:
          return "Complications (Infant)";

        case CallLogMetric.COMPLAINT_OF_INFANT:
          return "Complaint of Infant";

        case CallLogMetric.EPISOTOMY_WOUND_LSCS_WOUND:
          return "Episotomy wound/ LSCS wound";

        case CallLogMetric.LOCHIA_VAGINAL_DISCHARGE:
          return "Lochia/vaginal discharge";

        case CallLogMetric.BREAST_NIPPLE_CHANGES:
          return "Breast/Nipple changes";

        case CallLogMetric.WEIGHT_OF_MOTHER:
          return "Weight of mother";

        case CallLogMetric.COMPLICATIONS_MOTHER:
          return "Complications (Mother)";

        case CallLogMetric.COMPLAINTS:
          return "Complaint of Mom";

        case CallLogMetric.QUESTIONNAIRE:
          return "Questionnaire";

        case CallLogMetric.ALLERGIES:
          return "Allergies";

        case CallLogMetric.DIET_PLAN_SENT_DATE_AND_TIME:
          return "Diet plan sent Date & Time";

        case CallLogMetric.LINK:
          return "Link";

        case CallLogMetric.BIRTH_WEIGHT:
          return "Birth Weight";

        case CallLogMetric.RECUMBENT_LENGTH:
          return "Recumbent Length";

        case CallLogMetric.CURRENT_FEEDING_PATTERN:
          return "Current Feeding Pattern";

        case CallLogMetric.LAST_DOCTOR_VISITING_DATE:
          return "Last Doctor Visting Date";

        case CallLogMetric.CALL_TO:
          return "Call To";

        // case CallLogMetric.DO_YOU_HAVE_HELP_AT_HOME_TO_TAKE_CARE_OF_THE_CHILD:
        //   return "Do you have help at home to take care of the child?";

        // case CallLogMetric.PHYSICAL_ACTIVITY_FOR_MOM_POST_DELIVERY:
        //   return "Physical activity for mother post delivery";

        // case CallLogMetric.DISASTER_IF_ANY:
        //   return "Disaster (if any)";

        default:
          return "Some Metric";     // TODO : Needs to be removed
      }
  }

}
