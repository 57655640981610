export const environment = {
    production: true,
    studio: {
        logo: "/assets/images/hng-logo.png",
        title: "Delivering Care Made Easy",
        tagline: "Deliver enhanced connected care with EMR Studio resulting in better outcomes",
        loginHint: "Login to EMR Studio",
        loginToScm: "",
        trustHint: "Trusted by over 500 Physicians",
        copyRight: "HealthNet Global Pvt. Ltd.",
        eccLogo: "/assets/images/ECC_Logo.jpg",
        esicLogo: "/assets/images/esic-LOGO.jpg"
    }, 
    firebase: {
        apiKey: "AIzaSyApA40-KxSpJiAMPxN4hFxqiekr5gouZY0",
        authDomain: "vita-playground.firebaseapp.com",
        databaseURL: "https://vita-playground.firebaseio.com",
        projectId: "vita-playground",
        storageBucket: "vita-playground.appspot.com",
        messagingSenderId: "839295104914",
        appId: "1:839295104914:web:78dba22fe49d8f140bb34b",
        measurementId: "G-KSEFZPLPFE"
      },
    auth: {
        CLIENT_ID: "xdoiNJZc5uwmQFSMsZV0QsPZTFJ4T3aE",
        CLIENT_DOMAIN: "prohealth-hmd.us.auth0.com",
        AUDIENCE: "https://app.vitacloud.io",
        IAM_EXCHANGE_URL: "https://auth.play.vitacloud.io/prohealth/token/exchange",
        CONNECTION: "prohealth-hmd-staging",
        SCM_URL: ""
    },
    medexIam: {
        url: "http://localhost:2020",
        vitatoken: {
            getUserVitaToken: "/emrstudio/token/vitatoken",
        },
        // preferences: {
        //     add: "/preferences/add",
        //     get: "/preferences",
        //     getPreferenceByPreferenceTypeAndSourceId: "/preferences/preferenceType",
        // },
    },

    mongoCharts: {
        baseUrl: "https://charts.mongodb.com/charts-vitacloud-ccxyv",
        touchpointsCount: "900ac87d-68c7-4dcf-8555-dd78785835b2",
        prohealthByGender: "7c00251a-7ffd-475a-a30a-a02ce8888f5c",
        prohealthPackageUsersDistrubution: "a73aaa28-222b-4a24-a58f-0e619ab0d168",
        prohealthBmiDistribution: "799b83de-db9f-47f0-8c94-08603882f573",
        prohealthCustomerOverTime: "72d612c4-2660-45df-8e54-f7bb43cad298",
        usersandTouchPointsByCoach: "8c682e45-0c67-4d96-ba89-60a5ee8c806f",
        customersByLocation: "a1e0ad5f-b3cf-4629-b330-285f54791bfd",
        touchPointsOverMonth: "a95f26db-35d5-4e7d-9496-00d3abae5fa5",
        riskMatrix: "a622ffad-349f-4d76-9e78-5fff291fee09",
        userCountByTestDate: "40f8a138-e36b-4853-8e73-4e3b9dfb1676",
        touchPointsOverDays: "76972358-ac3d-4c45-95a1-1f7d16e8f792",
        touchPointsbyCoach: "65c0ad70-858f-4137-8146-b38f9a1d2cc5",
        touchPointsbyStatus: "65c0b4b8-8c9d-4fd7-8b0e-69193313c2e7",
        touchPointsbyChmMentor: "65c0b6e8-516b-4d18-8c6c-f075c2bac6cd",
        touchpointsbyMonth: "65c0b99d-7756-4859-8e57-80c8f06ebcc9",
        touchPointsAphc: "62f0946e-4642-42f9-8259-c5d41d7c6aa6",
        whatsAppMessagefollowUp: "63984b73-0ce4-4e8c-8f95-5ca025f8b753",
        messagesWhatsappSentByDate: "6469b865-fc7c-475f-8036-9d196e5dc22d",
        weeklyMessageAudit: "646c4714-c91e-4bd4-85cf-229be38bbc19",
        monthlyMessageAudit: "646c49dd-63a2-4212-8502-1441bf910a85",
        chmTouchpointLast14Days: "65c0aa28-4433-46cc-85bb-a7487b949feb",
        prohealthActiveUserCount: "65f86167-de11-4cb6-8a15-139cd2301eba",
        bmiRiskSeverityMonthwise: "65f86545-1278-4292-82ca-523370ab464f",
        diabetesRiskUsersbyDate: "65f90c9d-6398-4f2f-823c-8fea691c9621",
        userscountbyhypertensionrisk: "65f90da5-96bf-4081-8489-3f9f8741edaf",
        usersassignedtocoachtilldate: "65f9231a-b6b8-4ace-86b3-c1e0c6b95dec",
        cardiacRiskofusersbymonth: "65fbf88a-877d-41c8-898c-f70afe371c07",
        dysplipideamiarisk: "65fbf95f-f586-497e-8e87-20bc58581c42"
    },

    vitacloud: {
        package: {
            url: "https://payment.vitacloud.io",
            path: {
                getPackages: "/whitelist/medmantra",
            },
        },
        auth: {
            url: "https://auth.play.vitacloud.io",
            path: {
                dietChartNote: "/vitauser/dietplan/latest",
                dietPlanURL: "/vitauser/dietplan",
                addProvider: "/provider/add",
                listProviders: "/provider/list",
                authToken: "/provider/app/authtoken",
                listUsers: "/provider/app/users/list",
                registerUser: "/vitauser/app/addUser",
                updateUser: "/vitauser/update",
                addUser: "/provider/app/users/add",
                activateProgram: "/provider/app/user/activateprogram",
                addToCareTeam: "/vitauser/careteam/add",
                addBulkUserToCareTeam: "/vitauser/careteam/add/bulk",
                getUserVitaToken: "/vitauser/vitatoken",
                getUser: "/vitauser/get",
                getChatToken: "/vitachat/token",
                getSummary: "/vitasummary/get",
                getTouchPoint: "/vitasummary/get",

                removeProvider: "/vitauser/careteam/delete",
                getInclusions: "/vitauser/programdetails",
                saveInclusions: "/vitauser/add/inclusiondetails",

                getRegions: "/vitauser/medmantra/regions",

                getUserByUserId: "/vitauser/get/uhids",
                deleteTouchpoints: "/vitauser/update/status",
                addPreference: "/vitauser/preference",
                addNok: "/vitauser/preference/nok",
                getDietPlans: "/vitauser/careteam/dietplans",
                sendDietPlan: "/vitauser/dietplan/message",
                saveHMAction: "/vitauser/careteam/calllog",
                saveCallStatus: "/routine/dial"
            },
        },
        data: {
            url: "https://data.play.vitacloud.io",
            path: {
                getAlerts: "/alerts",
                addAlert: "/alerts/add",
                getGoals: "/goals/get/active",
                addGoal: "/goals/add",
                getTasks: "/tasks/get/active",
                getInstances: "/tasks/instances",
                addTasks: "/tasks/add",
                getfollowupDiagnostics: "/followup",

                userDietPlanAdd: "/dietplan/add",
                existingDietPlan: "/dietplan",
                mealTimeine: "/dietplan/meals",

                addFollowUp: "/followup/add",
                analytics: "/summary/followups",
            },
        },
        blob: {
            url: "https://blob.play.vitacloud.io",
            path: {
                reportsList: "/vitareports/list",
                reportFile: "/vitareports/report",
                addReport: "/vitareports/report/upload",
                deleteReport: "/vitareports/report/delete",
            },
        },
        care: {
            url: "https://care.play.vitacloud.io",
            path: {
                getNotes: "/notes",
                editNotes: "/notes/edit",
                addNotes: "/notes/add",
                deleteNotes: "/notes/delete",
                getCareCrap: "/carecrap",
                getProviderTasks: "/vita-care-flows/tasks",
            },
        },
        routines: {
            url: "https://routine.play.vitacloud.io",
            path: {
                saveHra: "/hra/add",
                getHistory: "/hra/history",
                addCallLogData: "/calllog/add",
                getCallLogData: "/calllog/get",
                getHraById: "/hra/get",
                addRoutine: "/routine/add",
                getRoutine: "/routine/get",
                listCallLogData: "/calllog/list",
                addInvestigation: "/diagnostics/add",
                listInvestigations: "/diagnostics/list",
                getInvestigation: "/diagnostics/get",
                deleteInvestigation: "/diagnostics/delete",
                addNotes: "/notes/add",
                listNotes: "/notes/list",

                addTouchpoint: "/routine/touchpoint/v2/add",
                addTouchpointStatus: "/routine/status/v2/add",
                editTouchpoints: "/routine/touchpoint/update",

                addAntenatal: "/antenatal/add",
                getAntenatal: "/antenatal/get",

                addLastVisit: "/lastvisit/add",
                getLastVisit: "/lastvisit/get",

                getImmunization: "/immunization/get",
                addImmunization: "/immunization/add",

                getFilteredVitaIds: "/routine/touchpoint/get",
                newTouchpoints: "/routine/user/touchpoints",

                listTouchpoints: "/routine/touchpoint/script/get",
                callhistorystatus: "/routine/dial"
            },
        },
        diet: {
            url: "https://community.play.vitacloud.io/v1",

            path: {
                getDietPlan: "/dietplan",
                getMealsData: "/dietplan/meals",
                addDietPlan: "/dietplan/add",
                addMealData: "/meals/add",
                deleteDietPlan: "/dietplan/delete",
            },
        },
        content: {
            url: "https://content.vitacloud.io",
            path: {
                searchContent: "/v1/search",
                getHabits: "/v1/habits/list",
                addHabits: "/v1/habits/add",
                deleteHabits: "/v1/habits/delete",
                getPostById: "/v1/posts/post",
                getCurriculumById: "/v1/programs/program",
                getConversationsProgress: "/v1/assistant/conversations/progress",
                addConversationStatus: "/v1/assistant/conversations/status/add",
            },
        },
        medexCommunicate: {
            url: "",
            path: {
                sendEmail: "/send/email",
                sendSms: "/send/sms",
                emailHistory: "/history/email",
                smsHistory: "/history/sms",
            },
        },
        rewards: {
            url: "https://reward.play.vitacloud.io",
            path: {
                points: "/rewards/points",
            },
        },
    },

    encounters: {
        url: "",
        create: "/encounters/create",
    },
};
